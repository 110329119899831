/* Keyframes for sliding in from the left */
@keyframes slideInFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Keyframes for sliding in from the right */
  @keyframes slideInFromRight {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  /* Keyframes for sliding in from the bottom */
  @keyframes slideInFromBottom {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

 /* Slide from left */
.slide-left {
    animation: slideInFromLeft 0.5s ease-in-out forwards;
  }
  
  /* Slide from right */
  .slide-right {
    animation: slideInFromRight 0.5s ease-in-out forwards;
  }
  
  /* Slide from bottom */
  .slide-bottom {
    animation: slideInFromBottom 0.5s ease-in-out forwards;
  }