
.app {
  color: #000;
  padding: 0;
  margin: 0;
  font-family: "Fredoka";
}
body {
  font-family: 'Fredoka', sans-serif;
}
/* Reset margin and padding for body and html */
html, body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home, .header {
  padding: 0;
  margin: 0;
}
.radio-label {
  color: rgb(255, 12, 206);
  font-size: 20px;
}
/* .radio {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
} */

.radio input[type="radio"] {
  display: none; /* Hide the native radio button */
}

.radio .radio-label {
  min-width: 70px; /* Ensures labels have a consistent width */
  text-align: right; /* Aligns the label text to the right */
}

.radio .radio-custom {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #333;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
  color: rgb(255, 12, 206);
  margin-left: 35px;
  margin-bottom: -5px;
}

.radio input[type="radio"]:checked + .radio-custom {
  background-color: rgb(255, 12, 206);
}

.radio .radio-custom::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  opacity: 0;
  transition: opacity 0.2s ease;
  color: rgb(255, 12, 206);
}

.radio input[type="radio"]:checked + .radio-custom::before {
  opacity: 1;
}
.padding-left {
  padding-left: 10px;
}
.padding-left-10 {
  padding-left: 15px;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 125px;
}
.header {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 12, 206);
  border-bottom: 4px solid #000;
}

.header h1 {
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-weight: 900;
  text-shadow: 0px 0px 4px #c2c2c2;
}

.radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
  min-height: 70px;
}
.text {
  color: rgb(255, 12, 206) !important;
  font-size: 20px;
}
.button {
  background-color: rgb(255, 12, 206);
  color: #000;
  font-size: 22px;
  border-radius: 8px;
  border: 1px solid black;
  box-shadow: 0 0 3px #363636;
  width: 150px;
  height: 70px;
  margin-top: 20px;
  margin-left: 0;
}
.button:hover {
  cursor: pointer;
  opacity: .7;
}
.selection-row {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.selection-row-2{
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
}
.selection-column {
  width: 90%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selection, .event-wrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: #000;
  margin-top: 20px;
  border: 2px solid rgb(255, 12, 206);
  border-radius: 15px;
  box-shadow: 0 0 3px #000;
  align-items: center;
  padding-bottom: 20px;
}
.textarea {
  width: 90%;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid rgb(46, 46, 46);
  font-size: 16px;
}
.event-add-btn {
  width: 120px;
  height: 30px;
  background-color: rgb(255, 12, 206);
  color: #000;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid black;
  box-shadow: 0 0 3px #363636;
  margin-top: 10px;
}
.event-close-btn {
  width: 120px;
  height: 30px;
  background-color: #1d1d1d;
  color: #ffffff;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid rgb(255, 12, 206);
  box-shadow: 0 0 3px #363636;
  margin-top: 10px;
}
.event-add-btn:hover, .event-close-btn:hover {
  cursor: pointer;
  opacity: .7;
}
.event-column-80 {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.event-column-20 {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.event-textarea {
  width: 90%;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid rgb(46, 46, 46);
  font-size: 16px;
  margin-top: 10px;
}
.about {
  width: 40%;
  text-align: center;
  font-size: 20px;
  padding-top: 20px;
}

.optional-text {
  font-size: 14px;
}

.material-symbols-outlined {
  color: #2196F3;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.info-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 0px;
  opacity: .8;
}
.info-icon:hover {
  cursor: pointer;
  opacity: 1;
}
.event-icon {
  width: 20px;
  height: 20px;
  opacity: .8;
}
.event-icon:hover {
  cursor: pointer;
  opacity: 1;
}
.info-box {
  width: 210px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 40px;
  background-color: #434343;
  color: #fff;
  text-align: center;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #797979;
}
.warning-text {
  font-size: 15px;
  color: #fa2d2d;
}

.selection-column-50 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selection-column-25 {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.selection-column-25-sm {
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.selection-p-title {
  color:rgb(255, 12, 206);
  font-size: 18px;
  text-decoration: underline;
  margin: 0;
  padding: 0;
}
.event-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-top: 5px;
}
.event-column-25 {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.event-column-40 {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.event-column-25 > h6,  
.event-column-25 > p, 
.event-column-40 > h6,  
.event-column-40 > p {
  color:rgb(255, 12, 206);
  font-size: 17px;
}
.selection-column-25-sm > p,
.selection-column-25-sm > h6 {
  padding: 0;
  margin: 0;
}
.event-title {
  color:rgb(255, 12, 206);
  font-size: 22px;
  margin: 0;
  padding: 0;
}
.selection-p {
  color:rgb(255, 12, 206);
  font-size: 16px;
  margin: 0;
  padding: 0;
}

.count-input {
  width: 50px;
  border: 1px solid rgb(255, 12, 206);
  border-radius: 4px;
  font-size: 16px;
}

.additional-column {
  width: 50%;
  display: flex;
  flex-direction: column;
 
  margin-top: 20px;
}

.additional-title {
  color: #000;
  font-size: 22px;
  text-decoration: underline;
  font-weight: 500;
}

.additional-p {
  color: #000;
  font-size: 18px;
}

.alert {
  position: absolute;
  z-index: 999;
  top: 10;
  left: 40;
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}

.alert-success {
  background-color: #4CAF50; /* Green */
}

.alert-error {
  background-color: #f44336; /* Red */
}

.alert-info {
  background-color: #2196F3; /* Blue */
}

.close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.close-btn:hover {
  opacity: 0.8;
}

.additional-title-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional-body {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.max-char-text {
  color: rgb(255, 12, 206);
}
.invites-container {
  width: 100%;
  max-height: 400px;
  overflow-x: auto;
  border-top: 1px solid rgb(255, 12, 206);
}
.change-screen-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9;
  width: 150px;
  height: 60px;
  font-size: 16px;
}

.add-invite-btn {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9;
  width: 40px;
  height: 40px;
  font-size: 19px;
  border-radius: 20px;
}

.add-invite {
  width: 95%;
  display: flex;
  position: absolute;
  top: 20px;
  left: 2.5%;
  background-color: rgb(255, 12, 206);
  height: 60px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-evenly;
  z-index: 99;
}
.add-invite-row {
  display: flex;
  align-items: center;
}
.add-invite-row-2 {
  display: flex;
  align-items: center;
}
.add-invite-input-name {
  height: 25px;
  width: 200px;
  border-radius: 3px;
  border: 1px solid black;
  margin-left: 3px;
}
.add-item-input {
  height: 25px;
  margin-left: 3px;
}
.add-invite-btn-2 {
  width: 60px;
  height: 30px;
  background-color: #1d1d1d;
  color: #fff;
  border: 1px solid #000;
  transition: .25s;
  border-radius: 3px;
}
.add-invite-btn-2:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}
.invite-section {
  position: relative;
}
.delete-invite-btn {
  width: 23px;
  position: absolute;
  top: 10px;
  left: -10px;
  background-color: #ff2c2c;
  color: #fff;
  font-size: 15px;
  border: 1px solid #ff2c2c;
  border-radius: 3px;
}
.delete-invite-btn:hover {
  background-color: #fff;
  color: #000;
  cursor: pointer;
}
.delete-invite-btn-2 {
  width: 23px;
  top: 36px;
  position: absolute;
  left: -10px;
  background-color: #ff2c2c;
  color: #fff;
  font-size: 15px;
  border: 1px solid #ff2c2c;
  border-radius: 3px;
  opacity: .7;
}
.yes-text {
  color: #78ff47 !important;
}
.no-text {
  color: #ff1f1f !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
